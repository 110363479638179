import Outlet from './components/Outlet';


function App() {
  return (
    <div className="contier">
      <Outlet/>
    </div>
  );
}

export default App;
