import React, { useState,lazy } from "react";

const Dashboard = lazy(()=>import('./dashboard/Dashboard'))


function Outlet() {
    const [authenticated, setAuthenticated] = useState(false);
    return (

      <>  
                {/* Si esta logueado se monta el dashboard el cual contiene las rutas privadas */}
                <Dashboard authenticated={authenticated}  setAuthenticated={setAuthenticated} />
      </>


    );
}

export default Outlet;
