import { useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";

const  override = {
  display: "block",
  margin: "0 auto",
  borderColor: "blue",
};



function Spinner() {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#072B88");

  return (
    <div className="sweet-loading">
      {/* <button onClick={() => setLoading(!loading)}>Toggle Loader</button> */}
      <input value={color} onChange={(input) => setColor(input.target.value)} placeholder="Color of the loader" type="hidden"/>

      <PulseLoader
        color={color}
        loading={loading}
        cssOverride={override}
        size={25}
        aria-label="Loading Spinner"
        data-testid="loader"
        speedMultiplier={0.5}
      />
    </div>
  );
}

export default Spinner;